import React from 'react';
import morseMicroLogo from "./images/morse-micro-whitebg.png";
import instaclustrLogo from "./images/instaclustr-whitebg.jpg";
import work180Logo from "./images/work180-whitebg.png";
import amazingCoLogo from "./images/amazingco-whitebg.png";
import workyardLogo from "./images/workyard-whitebg.jpg";
import altaLogo from "./images/alta-whitebg.png";
import chatterizeLogo from "./images/chatterize-whitebg.png";
import remoteSocialLogo from "./images/remotesocial.png";
import patchdLogo from "./images/patchd.png";
import qpayLogo from "./images/qpay.png";
import pushasLogo from "./images/pushas-whitebg.png";

const Startups = () => {

    // Name, Logo, ID (for css), URL
    const startupList = [
        ["Alta", altaLogo, "alta", "www.altavr.io", "Developing A Township Tale, a leading social VR multiplayer experience"],
        ["AmazingCo", amazingCoLogo, "amazingco", "www.amazingco.me", "Discover & book unique experiences for everyday activities & special occasions"],
        ["Chatterize", chatterizeLogo, "chatterize", "www.chatterize.com", "A supercharged English conversation app that accelerates spoken fluency"],
        ["Instaclustr", instaclustrLogo, "instaclustr", "www.instaclustr.com", "An open source as-a-service company, delivering reliability at scale"],
        ["Morse Micro", morseMicroLogo, "morsemicro", "www.morsemicro.com", "Fabless semiconductor company developing Wi-Fi HaLow solutions for the IoT"],
        ["Patch'd", patchdLogo, "patchd", "www.patchdmedical.com", "Early medical detection to prevent sepsis in vulnerable patients"],
        ["Pushas", pushasLogo, "pushas", "www.pushas.com", "Premium sneaker marketplace where you can safely buy and sell authentic, new sneakers"],
        ["QPay", qpayLogo, "qpay", "www.getqpay.com", "The platform for university payments & ticketing"],
        ["Remote Social", remoteSocialLogo, "remotesocial", "www.remotesocial.io", "Where teams come together to have fun and build connections"],
        ["WORK 180", work180Logo, "work180", "www.work180.co", "A global jobs network at the forefront of a new workplace revolution"],
        ["Workyard", workyardLogo, "workyard", "www.workyard.com", "Workforce software built for local construction and field services companies"],
    ]

    const startupJSXold = []

    for (const [index, value] of startupList.entries()) {
        startupJSXold.push(<p key={index}><a href={"https://" + value[3] + "/"}><img id={value[2]} src={value[1]} alt={value[0]}/></a></p>)
    }

    const startupJSX = []

    for (const [index, value] of startupList.entries()) {
        startupJSX.push(
            <div className="startup-box" key={index}>
                <a href={"https://" + value[3] + "/"}>
                <div className="startup-box-inner">
                    <div className="startup-box-front">
                        <img id={value[2]} src={value[1]} alt={value[0]}/>
                    </div>
                    <div className="startup-box-back">
                        <h2>{value[0]}</h2>
                        <p>{value[4]}</p>
                        <p className="link">{value[3]}</p>
                    </div>
                </div>
                </a>
            </div>
        )
    }

    return (
        <div className="section">
                <h2 id="startups">Startups</h2>
                <p>I'm an investor in a number of Australian startups.</p>

                <div id="new-startups">
                    {startupJSX}
                </div>
                <div className="clearfix"></div>
                <div id="startups">
                    {/*{startupJSX*/}
                </div>

                <p>I'm also a mentor at <a href="https://www.startmate.com.au/">Startmate</a>, one of Australia's best
                    startup accelerators.</p>
        </div>
    )
};

export default Startups
