import React from 'react';

const Numbers = () => {

    // get int from 1 to max
    const getRandomInt = (max) => Math.floor(Math.random() * max) + 1;
    const smallRandomNumber = () => getRandomInt(10)
    const largeRandomNumber = () => [25,50,75,100][getRandomInt(4)-1]

    const generateTargetNumber = () => {
        return 100 + getRandomInt(899)
    }
    const generateNumbers = () => {
        let largeNumberCount = getRandomInt(5) - 1; // 0-4
        let smallNumberCount = 6 - largeNumberCount;
        let generatedNumbers = [];
        let i;
        for (i = 0; i < largeNumberCount; i++)
            generatedNumbers.push(largeRandomNumber());
        for (i = 0; i < smallNumberCount; i++)
            generatedNumbers.push(smallRandomNumber());

        return generatedNumbers;
    }

    const defaultGeneratedNumbersDisplay = (numbers=generatedNumbers) => {
        return (
            <div>{numbers.map(x => <span className="number">{x}</span>)}</div>
        )
    }
    const [generatedNumbers, setGeneratedNumbers] = React.useState(generateNumbers())
    const [targetNumber, setTargetNumber] = React.useState(generateTargetNumber())
    const [generatedNumbersDisplay, setGeneratedNumbersDisplay] = React.useState(defaultGeneratedNumbersDisplay())

    const updateAnswer = event => {
        let validCharacters = "1234567890+-/*() ";
        let userEntry = event.target.value;
        let i;

        userEntry = userEntry.trim()
        if (!userEntry) {
            document.getElementById("answer").innerHTML = "";
            setGeneratedNumbersDisplay(defaultGeneratedNumbersDisplay());
            return;
        }
        else{
            userEntry = userEntry.replaceAll("x","*").replaceAll("X","*")
        }

        for (i = 0; i < userEntry.length; i++)
        {
            if (!validCharacters.includes(userEntry[i]))
            {
                // Invalid characters
                document.getElementById("answer").innerHTML = "";
                return;
            }
        }

        const extractNumbers = (numberString) => {
            // Take a string like "10 + (3x2)" and extract [10, 3, 2]
            let i;
            let workingNumber = "";
            let digits = "0123456789";
            let extractedNumbers = []
            for (i = 0; i < numberString.length; i++) {
                if (!digits.includes(numberString[i])) {
                    // Not a digit
                    if (workingNumber) {
                        extractedNumbers.push(parseInt(workingNumber));
                        workingNumber = "";
                    }
                } else {
                    // Digit
                    workingNumber = workingNumber + numberString[i];
                }
            }
            if (workingNumber) {
                extractedNumbers.push(parseInt(workingNumber));
                workingNumber = "";
            }
            return extractedNumbers;
        }

        // Dim Used Numbers
        let numberStatus = [];
        for (i = 0; i < generatedNumbers.length; i++) {
            numberStatus.push({
                'number': generatedNumbers[i],
                'used': false
            })
        }
        let extractedNumbers = extractNumbers(userEntry);

        // Check used number so we dim them
        let j;
        for (i = 0; i < extractedNumbers.length; i++) {
            for (j = 0; j < numberStatus.length; j++) {
                if (!numberStatus[j].used && numberStatus[j].number === extractedNumbers[i]) {
                    numberStatus[j].used = true;
                    break;
                }
            }
        }
        setGeneratedNumbersDisplay( <div>{numberStatus.map(x => <span className={x.used ? "number used" : "number"}>{x.number}</span>)}</div>)

        let value = 0;
        try {
            value = eval(userEntry);
            // Valid syntax but did we use excessive numbers?

            // Should rewrite this to use the code above
            let validNumbers = Array.from(generatedNumbers);
            let extractedNumbers = extractNumbers(userEntry);
            let validNumberFound;
            let j;
            let invalidEntry = false;
            for (i = 0; i < extractedNumbers.length; i++) {
                validNumberFound = false;
                for (j = 0; j < validNumbers.length; j++) {
                    if (extractedNumbers[i] === validNumbers[j]) {
                        validNumberFound = true;
                        validNumbers.splice([j], 1)
                        break
                    }
                }
                if (!validNumberFound)
                    invalidEntry = true;
            }

            if (invalidEntry)
                document.getElementById("answer").innerHTML = "";
            else
                document.getElementById("answer").innerHTML = value;
            return;
        }
        catch(err) {
            document.getElementById("answer").innerHTML = "";
            return;
        }
    }

    const generateNewNumber = () => {
        let newGeneratedNumbers = generateNumbers();
        setGeneratedNumbers(newGeneratedNumbers);
        setTargetNumber(generateTargetNumber());
        setGeneratedNumbersDisplay(defaultGeneratedNumbersDisplay(newGeneratedNumbers));
        document.getElementById('user-formula').value = '';
        document.getElementById('answer').innerHTML = '';
        document.getElementById('user-formula').focus();
    }

    return (
        <div id="numbers">
            Numbers! Try to write a formula to get close to the target number using the 6 numbers provided. <br/>
            (based on <a href="https://www.sbs.com.au/ondemand/program/letters-and-numbers">Letter and Numbers</a> on SBS)
            <div id="target-number">
                {targetNumber}
            </div>
            <div id="generated-numbers">
                {generatedNumbersDisplay}
            </div>
            <div id="formula">
                <input id="user-formula" autoComplete="off" placeholder="e.g. (1 + 2) * 3 - 4" onChange={updateAnswer}/>
            </div>
            <div id="answer">

            </div>
            <div id="new-number-button">
                <button onClick={generateNewNumber}>Generate New Numbers</button>
            </div>
        </div>
    )
}

export default Numbers
