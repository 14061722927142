import React from 'react';
import MCU from './images/mcu.thomasrice.com.jpg';

const Other = () => {
    return (
        <div className="section">
            <h2 id="other">Other Projects</h2>
            <p>Here are a few other things I've worked on.</p>
            <p><a href="https://github.com/thomasrice/google-calendar-automation">Google Calendar Automation</a> (on
                Github) - Python script to add multiple meetings to Google Calendar from a text file. Particularly
                useful for adding a large number of conference presentations in a different timezone.</p>
            <p><a href="https://worldnews.thomasrice.com/">World News</a> - Glance at the front page of many global
                news websites in one spot.</p>
            <p><a className="noborder" href="https://worldnews.thomasrice.com/"><img
                src="https://worldnews.thomasrice.com/screenshots/worldnews.thomasrice.com.png" alt="World News"/></a></p>
            <p><a href="https://mcu.thomasrice.com/">Marvel Cinematic Universe - Movie Selector</a> - plan which MCU
                movie
                to watch next based on what you've watched already.</p>
            <p><a className="noborder" href="https://mcu.thomasrice.com/"><img src={MCU} alt="Marvel Cinematic Universe - Movie Selector"/></a>
            </p>
            <p><a href="http://www.dragontavern.com/">Dragon Tavern</a> - A simple browser-based adventure game that
                takes 5 minutes to play. Launched May 16, 2008.</p>

        </div>
    )
};

export default Other
