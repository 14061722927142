import React, {useState, useEffect} from 'react';
import { Component, Fragment } from 'react';

const GoogleSearch = (props) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cse.google.com/cse.js?cx=014950901633591403705:abu2bwpjpo8";
    document.body.appendChild(script);
    return "";
};


const InnovationSearch = () => {
    const BarStyling = {width: "100%", background: "#F2F1F9", border: "none", padding: "0.5rem"};

    const urlParams = new URLSearchParams(window.location.search);
    const initialSearch = urlParams.get('search') || '';
    const [searchTerm, setSearchTerm] = React.useState(initialSearch);
    const [resultHtml, setResultHtml] = React.useState('');

    React.useEffect(() => {
        let ignore = false;

        if (searchTerm.length <= 2) {
            setResultHtml('')
            return;
        }

        setResultHtml('<p>Searching...</p>')

        const url = "/api/innovation_search?s=" + searchTerm;
        let savedTitle = '';

        fetch(url)
            .then(response => response.json())
            .then(result => {

                if (!ignore) {
                    if (!result.response.length) {
                        setResultHtml("<p>Nothing found.</p>")
                    } else {
                        let newResultHtml = '';
                        let i = 0;
                        for (i = 0; i < result.response.length; i++) {
                            if (result.response[i].issue_title != savedTitle) {
                                savedTitle = result.response[i].issue_title
                                newResultHtml += '<div class="innovation-wrap-title"><a href="' + result.response[i].url + '" target="_parent">' + savedTitle + '</a></div>'
                            }
                            newResultHtml += result.response[i].html;
                        }
                        if (result.response.length == 50) {
                            newResultHtml += '<div class="result-limit">You reached the maximum number of search results! Try refining your search terms.</div>'
                        }

                        setResultHtml(newResultHtml);
                    }
                }
            });
        return() => { ignore = true; }
    }, [searchTerm]);


    const onSearchInput = event => {
        setSearchTerm(event.target.value);
    };


    return (
        <div>
        <input
            style={BarStyling}
            value={searchTerm}
            placeholder="Search Thomas's Innovation Wrap"
            onChange={onSearchInput}
            autofocus="true"
        />
            <div dangerouslySetInnerHTML={{__html: resultHtml}}></div>
        </div>
    )
};

const InnovationWrapBox = (props) => {
    const divStyle = {
        backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ), url(" + props.issue.background + ")",
        backgroundRepear: 'no-repeat',
        backgroundPosition: 'center'
    };

    return (
        <div className="innovationwrap" style={divStyle}>
            <p><a href={props.issue.url}>Thomas's Innovation Wrap #{props.issue.number.toString()}</a></p>
            <p><a href={props.issue.url}>{props.issue.title}</a></p>
        </div>
    );
};

const InnovationWrap = () => {
    const entries = [
        {
            number: 65,
            title: "🔗 Coinbase cryptomania, ▶️ LOTR's pricey production, and 🛰️ Google's timelapse of Earth",
            url: "https://thomasrice.substack.com/p/-coinbase-cryptomania-lotrs-pricey",
            background: "https://cdn.substack.com/image/fetch/w_1200,c_limit,f_jpg,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F03dcdaa0-acf1-4841-9b93-28d6e8d41e82_1024x768.png"
        },
        {
            number: 64,
            title: "🔬 Reversible CRISPR, 💊 life-extending young blood, and 💲 the rise of robo-advisors",
            url: "https://thomasrice.substack.com/p/-reversible-crispr-life-extending",
            background: "https://cdn.substack.com/image/youtube/w_728,c_limit/rsCul1sp4hQ"
        },
        {
            number: 63,
            title: "💊 How mRNA-based therapies could change the world, 🌞 Biden's renewables boost, and 💻 the semi shortage",
            url: "https://thomasrice.substack.com/p/-how-mrna-based-therapies-could-change",
            background: "https://cdn.substack.com/image/youtube/w_728,c_limit/Ns__YpRneBA"
        },
        {
            number: 62,
            title: "🌞 Solar geoengineering, 💻 Intel's strategy update, and 🤖 breaking the blood-brain barrier",
            url: "https://thomasrice.substack.com/p/-solar-geoengineering-intels-strategy",
            background: "https://cdn.substack.com/image/fetch/w_1200,c_limit,f_jpg,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F20c34b7b-23f3-452d-9ffd-57456ad0c921_778x519.jpeg"
        },
        {
            number: 61,
            title: "💲 GameStop turmoil, 🏠 Clubhouse growth, and 🔋 GM going electric",
            url: "https://thomasrice.substack.com/p/-gamestop-turmoil-clubhouse-growth",
            background: "https://cdn.substack.com/image/fetch/w_1200,c_limit,f_jpg,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F413328ec-a2a3-43fe-8bf6-f3ef0f161c26_1440x1080.png"
        },
        {
            number: 60,
            title: "💊 Synthetic cornea implants, 👓 unsolved murders in VR, and 🐺 dire wolf DNA",
            url: "https://thomasrice.substack.com/p/-synthetic-cornea-implants-unsolved",
            background: "https://cdn.substack.com/image/youtube/w_728,c_limit/-sNW3F933v8"
        },
    ];


    return (
        <div className="section">

            <h2 id="innovation">Innovation Wrap</h2>
            <p>I summarise the latest technology & innovation news in a newsletter called <a
                href="https://thomasrice.substack.com/">Thomas's Innovation Wrap</a>.</p>
            <p>Past topics have included artificial intelligence, gene editing, renewables, VR/AR, streaming,
                quantum mechanics, space, cybersecurity, semiconductors, the app economy, gaming, synthetic biology, and
                more.</p>
            {/*<div>
                {entries.map((issue, key) => (
                    <InnovationWrapBox key={key} issue={issue}/>
                ))}
            </div>*/}
            <div>
                <p><b>Search Thomas's Innovation Wrap below:</b></p>
                <InnovationSearch/>
            </div>
        </div>
    );
};

export default InnovationWrap

