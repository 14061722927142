import React from 'react';

const Article = (props) => {
    return (
        <p><a href={props.article[2]}>{props.article[0]}</a> ({props.article[1]})</p>
    );
};

const Media = () => {

    // Title, Publication, URL
    const articles2021 = [
        ["Why this fundie backs AI-powered home sales – despite Zillow’s fall", "Australian Financial Review", "https://www.afr.com/chanticleer/why-this-fundie-backs-ai-powered-home-sales-despite-zillow-s-fall-20211130-p59dkp"],
        ["Behind Perpetual's brave bet on WeWork", "Australian Financial Review", "https://www.afr.com/markets/equity-markets/behind-perpetual-s-brave-bet-on-wework-20211125-p59byj"],
        ["A game-changing tech theme", "LiveWire", "https://www.livewiremarkets.com/wires/a-game-changing-tech-theme"],
        ["Eager gamers can’t be consoled as chip shortage set to ruin Christmas", "Sydney Morning Herlad", "https://www.smh.com.au/technology/eager-gamers-can-t-be-consoled-as-chip-shortage-set-to-ruin-christmas-20210624-p583u6.html"],
        ["How to play e-sports without getting burnt", "Australian Financial Review", "https://www.afr.com/wealth/personal-finance/how-to-play-e-sports-without-getting-burnt-20210126-p56wvl"],
        ["Frydenberg hits out at tech giants for ‘shifting goalposts’ on media code", "Sydney Morning Herald", "https://www.smh.com.au/politics/federal/frydenberg-hits-out-at-tech-giants-for-shifting-goalposts-on-media-code-20210124-p56wgz.html"]
    ];

    const articles2020 = [
        ["The new rules of investing for 2021", "Australian Financial Review", "https://www.afr.com/wealth/personal-finance/the-new-rules-of-investing-for-2021-20201221-p56pcx"],
        ["How Perpetual’s star fundie picked COVID winners", "Investor Daily", "https://www.investordaily.com.au/markets/48241-how-perpetual-s-star-fundie-picked-covid-winners"],
        ["Backing innovators with more upside than Amazon, Microsoft and Apple", "LiveWire", "https://www.livewiremarkets.com/wires/backing-innovators-with-more-upside-than-amazon-microsoft-and-apple"],
        ["The stockpicker returning 52.6pc", "Australian Financial Review", "https://www.afr.com/markets/equity-markets/the-stockpicker-returning-52-6pc-20200928-p55ztm"],
        ["Interview on new technologies with more potential to grow", "Firstlinks", "https://www.firstlinks.com.au/interview-series-whats-new-in-a-global-innovation-fund"],
        ["Grab your console: Investors bet on video game opportunities", "Morningstar", "https://www.morningstar.com.au/stocks/article/grab-your-console-investors-bet-on-video-game/205331"],
        ["Smart money eyes the top on Apple shares", "Australian Financial Review", "https://www.afr.com/markets/equity-markets/smart-money-eyes-the-top-on-apple-shares-20200901-p55r8f"],
        ["Apple's legal battle over Fortnite could change the iPhone forever", "Sydney Morning Herald", "https://www.smh.com.au/technology/apple-s-legal-battle-over-fortnite-could-change-the-iphone-forever-20200827-p55pth.html"],
        ["How top fundies beat the virus", "Australian Financial Review", "https://www.afr.com/chanticleer/how-top-fundies-beat-the-virus-20200714-p55byp"],
        ["Zoom tops Slack as investment bet, says leading tech fundie", "Australian Financial Review", "https://www.afr.com/markets/equity-markets/zoom-tops-slack-as-investment-bet-says-leading-tech-fundie-20200709-p55afz"]
    ];

    const oldArticles = [
        ["Spotify's symbolic (non) IPO - and why it's no Netflix for music", "Sydney Morning Herald", "https://www.smh.com.au/business/markets/spotify-s-symbolic-non-ipo-and-why-it-s-no-netflix-for-music-20180301-p4z2ad.html"],
        ["'FAANG' reporting season shows great American tech dream lives on", "Australian Financial Review", "https://www.afr.com/markets/faang-reporting-season-shows-great-american-tech-dream-lives-on-20180202-h0sd9q"],
        ["Getting to know Global Equities Analyst, Thomas Rice", "Perpetual website", "https://www.perpetual.com.au/adviser/news-and-insights/investing-matters-getting-to-know-thomas-rice"],
        ["Don't expect tech wreck 2.0, but beware of certain pockets", "Australian Financial Review", "https://www.afr.com/markets/live/dont-expect-tech-wreck-20-but-beware-of-certain-pockets-20160308-gndi0y"],
        ["What to make of the hype about global technology stocks", "Australian Financial Review", "https://www.afr.com/technology/what-to-make-of-the-hype-about-global-technology-stocks-20150825-gj6yib"],
        ["Local shares rebound on talk of Chinese stimulus", "Sydney Morning Herald", "https://www.smh.com.au/business/markets/local-shares-rebound-on-talk-of-chinese-stimulus-20140324-35cmr.html"],
        ["Christmas no factor in retail shorting", "AFR Smart Investor", "https://web.archive.org/web/20131111085749/http://www.afrsmartinvestor.com.au/p/specialist-investments/christmas_no_factor_in_retail_shorting_tYwL8jD2GK5U0sewf24e2H"],
        ["Why we love small-cap data company NEXTDC", "Switzer Report", "https://switzerreport.com.au/why-we-love-small-cap-data-company-nextdc/"]
    ]

    return (
        <div>
            <h2 id="media">Media and Interviews</h2>
            <h3>2021</h3>

            {articles2021.map((article, key) => (<Article key={key}article={article}/>))}

            <h3>2020</h3>
            {articles2020.map((article, key) => (<Article key={key} article={article}/>))}

            <h3>Before 2020</h3>
            {oldArticles.map((article, key) => (<Article key={key} article={article}/>))}

        </div>
    )
};

export default Media
